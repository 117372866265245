import React, { useState } from 'react';
import { AnimatePresence } from 'framer-motion';
import Detective from './Detective';
import SearchScreen from './SearchScreen';
import QuestionScreen from './QuestionScreen';
import ResultScreen from './ResultScreen';
import { useAIQuestions } from '../../hooks/useAIQuestions';

const MainContainer = () => {
    const [state, setState] = useState('normal');
    const [phase, setPhase] = useState('input');
    const [animeName, setAnimeName] = useState('');
    const [answers, setAnswers] = useState([]);
    const [episodeRange, setEpisodeRange] = useState({ min: 1, max: Infinity });
    const [confidence, setConfidence] = useState(0);
    const [skippedQuestions, setSkippedQuestions] = useState(0);

    const { getNextQuestion, loading, error } = useAIQuestions(animeName);
    const [currentQuestion, setCurrentQuestion] = useState(null);

    const handleSubmit = async () => {
        if (!animeName.trim()) return;

        setState('thinking');
        setPhase('question');

        try {
            const question = await getNextQuestion([]);
            if (question) {
                setCurrentQuestion(question);
                setConfidence(question.confidence || 0);
                setState('normal');
            }
        } catch (err) {
            console.error(err);
            setState('confused');
        }
    };

    const handleAnswer = async (answer, comment = '') => {
        if (loading) return;

        setState('thinking');

        // Handle "I don't know" responses
        if (answer === 'unknown') {
            setSkippedQuestions(prev => prev + 1);
            setState('confused');

            // Reduce confidence by a smaller amount (10% instead of previous larger drop)
            setConfidence(prev => Math.max(prev * 0.9, 20)); // Never drop below 20%

            // Store the comment if provided
            const newAnswer = {
                question: currentQuestion.question,
                answer: 'unknown',
                comment,
                confidence: confidence * 0.9
            };

            setAnswers(prev => [...prev, newAnswer]);

            // If too many questions are skipped, consider ending the session
            if (skippedQuestions >= 3) {
                setState('confused');
                setPhase('result');
                return;
            }

            // Get a new question without updating the episode range
            try {
                const nextQuestion = await getNextQuestion(answers);
                if (nextQuestion) {
                    setCurrentQuestion(nextQuestion);
                    setState('normal');
                } else {
                    setState('confused');
                    setPhase('result');
                }
            } catch (err) {
                console.error(err);
                setState('confused');
            }
            return;
        }

        const newAnswer = {
            question: currentQuestion.question,
            answer: answer === 'yes',
            episodeRange: currentQuestion.episodeRange[answer === 'yes' ? 'yes' : 'no'],
            confidence: currentQuestion.confidence
        };

        const newAnswers = [...answers, newAnswer];
        setAnswers(newAnswers);

        const newRange = currentQuestion.episodeRange[answer === 'yes' ? 'yes' : 'no'];
        setEpisodeRange(newRange);
        setConfidence(currentQuestion.confidence || confidence);

        const shouldComplete =
            newAnswers.length >= 10 ||
            currentQuestion.confidence >= 90 ||
            (newRange.max - newRange.min) <= 5 ||
            skippedQuestions >= 3;

        if (shouldComplete) {
            setState(confidence > 70 ? 'happy' : 'confused');
            setPhase('result');
        } else {
            try {
                const nextQuestion = await getNextQuestion(newAnswers);
                if (nextQuestion) {
                    setCurrentQuestion(nextQuestion);
                    setState('normal');
                } else {
                    setState('happy');
                    setPhase('result');
                }
            } catch (err) {
                console.error(err);
                setState('confused');
            }
        }
    };

    const restart = () => {
        setState('normal');
        setPhase('input');
        setAnimeName('');
        setAnswers([]);
        setEpisodeRange({ min: 1, max: Infinity });
        setConfidence(0);
        setCurrentQuestion(null);
        setSkippedQuestions(0);
    };

    return (
        <div className="min-h-screen bg-gradient-to-br from-purple-50 to-blue-50 p-4 flex flex-col items-center justify-center">
            <div className="w-full max-w-xl mx-auto">
                <Detective state={state} />

                <AnimatePresence mode="wait">
                    {phase === 'input' && (
                        <SearchScreen
                            onSubmit={handleSubmit}
                            animeName={animeName}
                            setAnimeName={setAnimeName}
                        />
                    )}

                    {phase === 'question' && currentQuestion && (
                        <QuestionScreen
                            question={currentQuestion.question}
                            progress={confidence}
                            onAnswer={handleAnswer}
                            loading={loading}
                        />
                    )}

                    {phase === 'result' && (
                        <ResultScreen
                            episodeRange={episodeRange}
                            confidence={confidence}
                            answersLength={answers.length}
                            selectedAnime={animeName}
                            onRestart={restart}
                            skippedQuestions={skippedQuestions}
                        />
                    )}
                </AnimatePresence>
            </div>
        </div>
    );
};

export default MainContainer;