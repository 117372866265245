import React from 'react';
import { motion } from 'framer-motion';
import { Sparkles, RefreshCw } from 'lucide-react';

const ResultScreen = ({ episodeRange, confidence, answersLength, selectedAnime, onRestart }) => {
  const containerVariants = {
    hidden: { opacity: 0, scale: 0.8 },
    visible: { 
      opacity: 1, 
      scale: 1,
      transition: { 
        type: "spring",
        stiffness: 200,
        damping: 20
      }
    },
    exit: {
      opacity: 0,
      scale: 0.8,
      transition: { duration: 0.3 }
    }
  };

  return (
    <motion.div
      variants={containerVariants}
      initial="hidden"
      animate="visible"
      exit="exit"
      className="bg-white rounded-2xl shadow-xl p-8 text-center"
    >
      <motion.div
        initial={{ rotate: 0 }}
        animate={{ rotate: 360 }}
        transition={{ duration: 1 }}
      >
        <Sparkles className="w-16 h-16 text-yellow-500 mx-auto mb-4" />
      </motion.div>
      
      <div className="mb-4">
        <h2 className="text-2xl font-bold bg-gradient-to-r from-purple-600 to-blue-600 bg-clip-text text-transparent">
          {episodeRange.min === episodeRange.max 
            ? `Episode ${episodeRange.min} Found!`
            : `Episodes ${episodeRange.min}-${episodeRange.max}`}
        </h2>
        
        <div className="mt-4 mb-6">
          <div className="w-full bg-purple-100 rounded-full h-2.5 mb-2">
            <motion.div 
              className="bg-gradient-to-r from-purple-600 to-blue-600 h-2.5 rounded-full"
              initial={{ width: 0 }}
              animate={{ width: `${confidence}%` }}
              transition={{ duration: 1, delay: 0.5 }}
            />
          </div>
          <p className="text-sm text-gray-600">
            {Math.round(confidence)}% Confident
          </p>
        </div>
      </div>

      <p className="text-gray-600 mb-6">
        Based on your {answersLength} answers about {selectedAnime}
      </p>

      <motion.button
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        onClick={onRestart}
        className="bg-gradient-to-r from-purple-600 to-blue-600 text-white px-8 py-3 rounded-xl flex items-center space-x-2 mx-auto hover:from-purple-700 hover:to-blue-700 transition-all duration-200"
      >
        <RefreshCw className="w-5 h-5" />
        <span>Try Again</span>
      </motion.button>
    </motion.div>
  );
};

export default ResultScreen;