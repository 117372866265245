import React from 'react';
import { motion } from 'framer-motion';

const Detective = ({ state }) => {
  const eyeVariants = {
    thinking: {
      scale: [1, 1.2, 1],
      transition: {
        repeat: Infinity,
        duration: 2
      }
    },
    normal: { scale: 1 },
    happy: {
      scale: [1, 0.9, 1],
      transition: {
        repeat: Infinity,
        duration: 1
      }
    },
    confused: {
      rotate: [0, -10, 10, 0],
      transition: {
        repeat: Infinity,
        duration: 2
      }
    }
  };

  return (
    <motion.div 
      className="w-48 h-48 bg-purple-100 rounded-full mx-auto mb-8 relative overflow-hidden"
      animate={{
        rotate: state === 'thinking' ? [0, 5, -5, 0] : 0,
        scale: state === 'happy' ? [1, 1.1, 1] : 1
      }}
      transition={{
        repeat: state === 'thinking' ? Infinity : 0,
        duration: 2
      }}
    >
      {/* Face */}
      <div className="absolute inset-0 flex items-center justify-center">
        <motion.div 
          className="flex space-x-8"
          variants={eyeVariants}
          animate={state}
        >
          {/* Eyes */}
          <div className="w-6 h-6 bg-purple-900 rounded-full" />
          <div className="w-6 h-6 bg-purple-900 rounded-full" />
        </motion.div>
        {/* Mouth */}
        <motion.div 
          className="absolute w-16 h-1 bg-purple-900 rounded-full"
          style={{ bottom: '30%' }}
          animate={{
            scale: state === 'happy' ? [1, 1.2, 1] : 1,
            height: state === 'happy' ? 8 : state === 'confused' ? 4 : 2,
            rotate: state === 'confused' ? 180 : 0
          }}
        />
      </div>
    </motion.div>
  );
};

export default Detective;