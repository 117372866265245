import { useState, useCallback, useRef } from 'react';
import { askGPT } from '../utils/openai';

export const useAIQuestions = (animeName) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [tokenCount, setTokenCount] = useState(0);
    const animeNameRef = useRef(animeName);
    
    // Update ref when animeName changes
    animeNameRef.current = animeName;

    const getNextQuestion = useCallback(async (previousAnswers = []) => {
        console.log('\n=== Getting Next Question ===');
        console.log('Using animeName:', animeNameRef.current);
        console.log('Previous answers:', previousAnswers);
        
        if (!animeNameRef.current) {
            console.log('No anime name provided, returning early');
            return null;
        }

        setLoading(true);
        setError(null);

        try {
            const answerContext = previousAnswers.map(a => 
                `User ${a.answer ? 'has' : 'has not'} seen: "${a.question}"`
            ).join('\n');

            const messages = [
                {
                    role: "system",
                    content: `You are an expert anime detective helping users find their last watched episode of "${animeNameRef.current}". You have information about all episodes and major plot points.

Current user answers:
${answerContext}

Your task is to ask strategic yes/no questions about major plot points to narrow down where they stopped watching.

Guidelines:
- Start with questions about early major events if this is the first question
- Ask about specific, memorable events from specific episodes
- Each question should help narrow the episode range significantly
- Questions should become more specific based on previous answers
- Consider the chronological order of events

Return ONLY a JSON response in this exact format:
{
    "question": "Have you seen [specific plot point/event]?",
    "episodeRange": {
        "yes": {"min": X, "max": Y},
        "no": {"min": A, "max": B}
    },
    "confidence": N
}`
                },
                {
                    role: "user",
                    content: JSON.stringify({
                        command: "Please ask the next question to help find where they stopped watching " + animeNameRef.current,
                        currentInfo: {
                            animeName: animeNameRef.current,
                            previousAnswers: previousAnswers,
                            currentEpisodeRange: previousAnswers.length > 0 
                                ? previousAnswers[previousAnswers.length - 1].episodeRange 
                                : { min: 1, max: 100 }
                        }
                    })
                }
            ];

            console.log('Sending request to API...');
            const response = await askGPT(messages);
            console.log('API Response:', response);

            if (!response || !response[0]) {
                throw new Error('No response from AI');
            }

            const aiResponse = response[0];
            console.log('Processed AI Response:', aiResponse);

            if (!aiResponse.question || !aiResponse.episodeRange) {
                throw new Error('Invalid response format from AI');
            }

            const estimatedTokens = JSON.stringify(messages).length / 4;
            setTokenCount(estimatedTokens);

            return {
                ...aiResponse,
                tokenCount: estimatedTokens
            };
        } catch (err) {
            console.error('Error in getNextQuestion:', err);
            setError(err.message);
            return null;
        } finally {
            setLoading(false);
        }
    }, []); // Empty dependency array since we're using ref

    return {
        getNextQuestion,
        loading,
        error,
        tokenCount
    };
};