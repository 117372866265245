export const dynamic = 'force-dynamic';

export async function askGPT(messages, amount = 1, json = true) {
    try {
        console.log('Sending request with messages:', messages);

        const response = await fetch('http://localhost:3001/api/chat', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                messages,
                amount,
                json
            })
        });

        if (!response.ok) {
            const errorText = await response.text();
            console.error('API Error Response:', errorText);
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        console.log('API Response:', data);
        return data.responses;
    } catch (error) {
        console.error("API Error:", error);
        throw new Error(`Failed to get response: ${error.message}`);
    }
}