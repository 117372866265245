import React from 'react';
import { motion } from 'framer-motion';
import { ThumbsUp, ThumbsDown, HelpCircle } from 'lucide-react';

const QuestionScreen = ({ question, progress, onAnswer, loading }) => {
  const containerVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: { duration: 0.5 }
    },
    exit: {
      opacity: 0,
      y: -20,
      transition: { duration: 0.3 }
    }
  };

  return (
    <motion.div
      variants={containerVariants}
      initial="hidden"
      animate="visible"
      exit="exit"
      className="bg-white rounded-2xl shadow-xl p-8"
    >
      <div className="mb-6">
        <div className="flex items-center justify-between mb-2">
          <span className="text-sm text-gray-500">Investigation Progress</span>
          <span className="text-sm font-medium text-purple-600">{progress}%</span>
        </div>
        <div className="w-full h-2 bg-purple-100 rounded-full overflow-hidden">
          <motion.div
            className="h-full bg-gradient-to-r from-purple-600 to-blue-600"
            initial={{ width: 0 }}
            animate={{ width: `${progress}%` }}
            transition={{ duration: 0.5 }}
          />
        </div>
      </div>

      <h3 className="text-xl text-center mb-8">
        {question}
      </h3>

      <div className="flex justify-center space-x-4 mb-4">
        <motion.button
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          className="px-8 py-3 bg-green-500 text-white rounded-xl flex items-center space-x-2 hover:bg-green-600 transition-colors duration-200"
          onClick={() => onAnswer('yes')}
          disabled={loading}
        >
          <ThumbsUp className="w-5 h-5" />
          <span>Yes</span>
        </motion.button>

        <motion.button
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          className="px-8 py-3 bg-red-500 text-white rounded-xl flex items-center space-x-2 hover:bg-red-600 transition-colors duration-200"
          onClick={() => onAnswer('no')}
          disabled={loading}
        >
          <ThumbsDown className="w-5 h-5" />
          <span>No</span>
        </motion.button>
      </div>

      <div className="flex justify-center">
        <motion.button
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          className="px-8 py-3 bg-purple-500 text-white rounded-xl flex items-center space-x-2 hover:bg-purple-600 transition-colors duration-200"
          onClick={() => onAnswer('unknown')}
          disabled={loading}
        >
          <HelpCircle className="w-5 h-5" />
          <span>I Don't Know</span>
        </motion.button>
      </div>
    </motion.div>
  );
};

export default QuestionScreen;