import React from 'react';
import { WhereDidIStop } from './components/WhereDidIStop';

function App() {
  return (
    <div className="min-h-screen bg-gradient-to-br from-purple-100 to-blue-100 p-4 animate-gradient">
      <header className="text-center py-6">
        <h1 className="text-3xl font-bold text-purple-900">WhereDidIStop.com</h1>
        <p className="text-gray-600">Find where you left off in your anime journey</p>
      </header>
      <main className="container mx-auto max-w-2xl">
        <WhereDidIStop />
      </main>
    </div>
  );
}

export default App;